.backdrop-full {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left h1 {
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px;
}

.header-center {
  display: flex;
  gap: 20px;
}

.header-item {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;
}

.header-item a {
  font-size: 16px;
  font-weight: bold;
  color: blue;
  text-decoration: none;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.save-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: purple;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #6a0dad;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: gray;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: black;
}

.body-modal {
  display: grid;
  grid-template-columns: 290px 1fr;
}

.preview_html {
  /* width: 435px; */
}

.list-modal {
  display: flex;
  flex-direction: column;
}
/* 
.list-modal ul {
  overflow-x: scroll;
} */

.list-modal li {
  border: solid 1px #ccc;
  grid-template-columns: auto 1fr auto;
  gap: 10px;
  display: grid;
  margin-top: 10px;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
}

.list-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.list-modal li.active {
  border: solid 1px var(--colors-brandColorTertiaryPure);
}

.icon {
  justify-self: end;
  color: var(--colors-brandColorTertiaryPure);
}

.list-modal h2 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.list-modal li p {
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.date-picker {
  position: absolute;
  /* bottom: 0; */
  z-index: 99;
}

@media (min-width: 1200px) {
  .modal-xl {
    width: 90% !important;
  }
}

.radio {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 13.5px;
  height: 13.5px;
}

.active .radio {
  border-width: 5px;
  border-color: var(--colors-brandColorTertiaryPure);
}

.preview_content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.input-time {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding-left: 5px;
  padding: 5px;
}
