.my-active-page.active > .page-link {
    background-color: #A500B9 !important;
    border-color: #A500B9 !important;
    border-radius: 50%;
  }

.page-item > .page-link {
    margin: 0 4px;
    border: 0;
}

ul.pagination {
  z-index: 0;
}