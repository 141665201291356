/* .rbc-toolbar{
  display: none !important;
} */

.rbc-event-content {
  font-size: 10px;
}

body {
  zoom: 85%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* *,
*::before,
*::after {
  z-index: 0;
} */
.is-invalid {
  border-color: #6300ff !important;
  box-shadow: 0 0 0 0.2rem rgba(99, 0, 255, 0.25) !important;
  background-image: none !important;
}

.content-modal-app {
  padding: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.content-modal-app > div:first-child {
  padding-right: 25px;
}

.content-modal-app .item {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 40px;
  font-weight: bold;
}

.content-modal-app .item span {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  margin-right: 10px;
}

.content-modal-app .item .tooltip-area span {
  font-size: var(--fontSizes-fontSizeXS);
  color: var(--colors-brandColorTertiaryPure);
  background: transparent;
  border: 0;
}

.capas {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.capas li {
  width: 162px;
  height: 162px;
  border-radius: 8px;
  background: #f5f5f5;
  padding: 16px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.capas li div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.capas li .select {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #e0e0e0;
  right: 16px;
  border-radius: 50%;
}

.capas li .select.selected {
  border-width: 5px;
  border-color: #a500b9;
}

.preview-carousel {
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 30%;
  padding-top: 8rem;
  flex-direction: column;
}

.preview-carousel__item {
  padding: 10px;
  background: #fff;
  box-shadow: 2px 2px 20px #ccc;
  position: relative;
  width: 100%;
}

.preview-carousel__item .profile {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999999;
}

.preview-carousel__item .profile img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #a500b9;
}

.preview-carousel__item .profile p {
  margin: 0;
  margin-left: 10px;
  color: #fff;
  font-weight: bold;
}

.preview-carousel__item figure {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: #a3a3a3;
  width: 100%;
}

.preview-carousel__item img {
  object-fit: cover;
  height: 100%;
}

.preview-carousel__item .carousel,
.carousel-inner,
.carousel-indicators {
  height: 100%;
}

.preview-carousel__item--1-1 img {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.preview-carousel__item--4-5 img {
  width: 100%;
  aspect-ratio: 4 / 5;
}

.preview-carousel__item--119-100 img {
  width: 100%;
  aspect-ratio: 1.19 / 1;
}

.preview-carousel__item--1-1 {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.preview-carousel__item--4-5 {
  width: 100%;
  aspect-ratio: 4 / 5;
}

.preview-carousel__item--119-100 {
  width: 100%;
  aspect-ratio: 1.19 / 1;
}

.small-preview {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}

.small-preview li {
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small-preview img {
  width: 60px;
  height: 64px;
  object-fit: cover;
}

.select-container .css-1nmdiq5-menu div div {
  background-color: #ffffff !important;
  color: #000 !important;

  &:hover {
    background-color: #f5f5f5 !important;
  }
}
.success-save {
  transition: all 0.3s;
  background-color: #1b7d2c !important;
  border-color: #1b7d2c !important;
  color: #fff !important;

  .material-icons {
    margin-left: 5px;
  }
}

.success-save:hover {
  transition: all 0.3s;
  background-color: #209734 !important;
  border-color: #209734 !important;
}

.scheduled {
  background-color: #fae703;
  color: #000;
}

.badge-sent {
  background-color: #6300ff;
  color: #fff;
}

.badge-received {
  background-color: #1b7d2c !important;
  color: #fff;
}

.badge-read {
  background-color: #ff7503;
  color: #fff;
}

.badge-clicked {
  background-color: #48e498;
  color: #000;
}

.badge-not-delivered {
  background-color: #a3a3a3;
  color: #fff;
}

.file-container {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  display: grid;
  width: 99%;
  margin-bottom: 10px;
  grid-template-columns: 45px 1fr;
  cursor: pointer;
}

.file-container img {
  max-width: 40px;
}

.datepicker-container .react-datepicker {
  width: 330px;
}

.react-datepicker__day--selected {
  background-color: var(--colors-brandColorPrimaryPure) !important;
  color: white;
}

.react-datepicker__time-list-item--selected {
  background-color: var(--colors-brandColorPrimaryPure) !important;
}
