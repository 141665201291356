/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro Regular';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('fonts/SourceSansPro/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/SourceSansPro/source-sans-pro-v21-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Source Sans Pro Medium';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('fonts/SourceSansPro/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/SourceSansPro/source-sans-pro-v21-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro Bold';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('fonts/SourceSansPro/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/SourceSansPro/source-sans-pro-v21-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-code-regular - latin */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('fonts/FiraCode/fira-code-v21-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/FiraCode/fira-code-v21-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('fonts/OpenSans/open-sans-v29-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/OpenSans/open-sans-v29-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-500 - latin */
@font-face {
  font-family: 'Open Sans Medium';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('fonts/OpenSans/open-sans-v29-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/OpenSans/open-sans-v29-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('fonts/OpenSans/open-sans-v29-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/OpenSans/open-sans-v29-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

