.communication-report-table .accordion-item {
    border: none !important;
    border-bottom: 2px solid #eee !important;
    border-radius: 0;
    margin-bottom: 8px;
}

.communication-report-table .accordion-button {
    padding-left: 0 !important;
}

.communication-report-table .accordion-button:not(.collapsed) {
    color: #a500b9;
    background-color: #fff;
}

.communication-report-table .accordion-button:not(.collapsed)::after {
    filter: brightness(0) !important;
}
.communication-report-table .accordion-button:focus {
    box-shadow: 0 0 5px 2px #f0c9f0 !important;
}

.communication-report-table .form-check-input:checked {
    background-color: #a500b9;
    border-color: #9403a4;
}

.communication-report-table .form-check-label {
    font-size: large;
}

.table-row {
    border-bottom: 2px solid #ddd !important;
}

.bold {
    font-weight: bold;
}

.bg-gray-light {
    background-color: #F5F5F5;
}
