.drawer-filter {
  border-radius: 2rem;
  width: 55rem !important;
  padding-left: 2rem;
  padding-top: 2rem;

  .offcanvas-footer {
    padding-bottom: 2rem;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.drawer-filter-backdrop.show {
  opacity: 0;
}

.drawer-filter-cancel-button,
.drawer-filter-apply-button,
.drawer-filter-clean-filter-button {
  display: inline-block;
  margin-right: 0px;
  padding: 8px 16px !important;
}

.drawer-filter-apply-button,
.drawer-filter-clean-filter-button {
  margin-left: 1rem;
}

.drawer-filter-cancel-button,
.drawer-filter-clean-filter-button {
  background-color: white;
  color: black;
  border: 3px solid #ddd;
  border-radius: 10px;
  padding: 8px 25px;
  cursor: pointer;
  font-size: 18px;
}

.drawer-filter-cancel-button:hover,
.drawer-filter-clean-filter-button:hover {
  background-color: #f9f9f9;
}

.drawer-filter-apply-button {
  background-color:#a500b9;
  color: white;
  border: 3px solid #a500b9;
  border-radius: 10px;
  padding: 8px 25px;
  cursor: pointer;
  font-size: 18px;
}

.drawer-filter-apply-button:hover {
  background-color: #8a00a0;
}

.drawer-filter-clean-filter-button {
  border: 3px solid #a500b9;
}
