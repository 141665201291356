.labeled-progress-bar-card {
  padding: 0.5rem;
  border: 1px solid #d3d3d3;
}

.labeled-progress-bar {
  height: .75rem !important;
  .progress-bar {
    height: .75rem !important;
    background-color: #A500B9 !important;
  }
}